<template>
  <div>
    <gql-table
      ref="usersTable"
      :fields="fields"
      :query="query"
      query-type="users"
      :sort-desc="true"
      :per-page-max="1"
      :actions="userActionsList"
      v-on="userHandlers"
    >
      <template #cell(full_name)="data">
        <b-link :to="{name: 'user-detail-ext', params: {id: data.item.id}}">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </b-link>
      </template>

      <template #cell(userEmail)="data">
        {{ data.item.email }}
      </template>

    </gql-table>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import userActions from '@/mixins/userActions'

const fields = [
  {
    name: 'full_name',
    label: 'Full name',
  },
  {
    name: 'firstName', label: 'First name', visible: false,
  },
  {
    name: 'lastName', label: 'Last name', visible: false,
  },
  {
    name: 'userEmail',
    label: 'Email',
    filterable: 'eq',
    sortable: true,
  },
  {
    name: 'actions',
  },
]

const query = [
  'firstName',
  'lastName',
  'id',
  'email',
]

export default {
  components: {
    BLink,
    GqlTable,
  },
  mixins: [userActions],
  data() {
    return {
      fields,
      query,
      userActionsFilterIn: ['loginAs'],
    }
  },
  methods: {
    reload() {
      this.$refs.usersTable.reloadAll(true)
    },
  },
}
</script>
